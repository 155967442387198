@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&family=Poppins:wght@700&display=swap");
//design for the new quote component
input[type="number"] {
  -moz-appearance: textfield;
}

// @font-face {
//   font-family: "MainFont";
//   src: url("../fonts/SinkinSansLight.ttf");
// }
// -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
@font-face {
  font-family: "OpenSansItalic";
  src: local("OpenSansItalic"),
    url("../fonts/OpenSans-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "OpenSansRegular";
  src: local("OpenSansRegular"),
    url("../fonts/OpenSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "OpenSansSemiBold";
  src: local("OpenSansSemiBold"),
    url("../fonts/OpenSans-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoSlabRegular";
  src: local("RobotoSlabRegular"),
    url("../fonts/RobotoSlab-Regular.ttf") format("truetype");
}

.font-family-open-sans-semi-bold {
  font-family: "MontserratRegular" !important;
}

.font-family-open-sans-italic {
  font-family: "OpenSansItalic" !important;
}

// -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
@font-face {
  font-family: "MontserratRegular";
  src: local("MontserratRegular"),
    url("../fonts/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "MontserratMedium";
  src: local("MontserratMedium"),
    url("../fonts/Montserrat-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "MontserratSemiBold";
  src: local("MontserratSemiBold"),
    url("../fonts/Montserrat-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "MontserratBold";
  src: local("MontserratBold"),
    url("../fonts/Montserrat-Bold.ttf") format("truetype");
}

.font-family-montserrat-bold {
  font-family: "MontserratBold";
}

.font-family-montserrat-semi-bold {
  font-family: "MontserratSemiBold";
}

.font-family-montserrat-medium {
  font-family: "MontserratMedium";
}

.font-family-montserrat-regular {
  font-family: "MontserratRegular";
}

input {
  padding: 0.5rem;
}

.per5 {
  width: 5%;
  max-width: 5%;
}

.per7 {
  width: 6%;
  max-width: 6%;
}

.per20 {
  width: 20%;
  max-width: 20%;
}

.per10 {
  max-width: 10%;
  width: 10%;
}

body {
  font-family: "MontserratRegular" !important;
  -webkit-font-smoothing: antialiased;
  color: black;
  // font-size: 13.7px;
  font-size: 15px;
}

// body {
//   font-family: MainFont, Arial, Georgia, sans-serif !important;
//   -webkit-font-smoothing: antialiased;
//   // font-size: 13.7px;
//   font-size: 12.8px;
//   height: 100vh !important;
// }

.logout-div {
  margin-right: 2.5rem;
  font-size: 16px;
}

.logout-div:hover {
  text-decoration: underline;
  cursor: pointer;
}

.second {
  .btn {
    width: 200px;
    font-size: 20px;
    font-family: sans-serif;
    border-radius: none;
  }
}

.signup,
.tab-content-login .image-block {
  word-break: break-word;
  font-family: "Optima";
  font-weight: 400;
  line-height: 1.1;
  .display-4 {
    font-weight: 700;
  }
  .footer {
    width: 100%;
    height: 3rem;
    background-color: #183650;
    font-size: 2rem;
    a,
    span {
      margin-right: 2.5rem;
      color: white;
    }
    span:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .home-form {
    background-color: #e4eaef;
    .formTitle {
      text-align: center;
      font-weight: 700;
    }
    form {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-flow: column;
    }
    input::placeholder,
    textarea::placeholder {
      color: #9a9c9e71;
    }
    input,
    textarea {
      width: 80%;
      color: #858d94;
      height: 50px;
      font-size: x-large;
      margin-bottom: 1.5rem;
      border: none;
      font-weight: 500;
      font-family: sans-serif;
    }
    div[class^="col-"] {
      display: flex;
      justify-content: center;
    }
    .btn {
      width: 200px;
      font-size: 20px;
      font-family: sans-serif;
      background-color: #25c4f3;
      border: none;
      border-radius: 0px;
    }
    a {
      text-decoration: none;
      color: white;
    }
  }
  .minReq {
    background-color: #e4eaef;
    padding: 1.5rem 7rem;
    i {
      color: #5d5d5d;
      font-size: 40px;
    }
    ul {
      margin-top: 20px;
      // font-family: sans-serif;
      font-size: 30px;
      font-weight: 400;
    }
  }
  .whyWorkComp {
    padding: 1.5rem 7rem;
    .row {
      margin-top: 20px;
    }
    div[class^="col-"] {
      display: flex;
      justify-content: start;
    }
    img {
      height: 80px;
      margin-right: 10px;
    }
    p {
      font-size: 30px;
    }
  }
  .header {
    width: 100%;
    .logos-container {
      padding: 0.4rem 0;
      margin-left: 0.5rem !important;
      margin-right: 2.5rem;
      .wcn-logo-container {
        height: 6.65rem;
        img {
          max-height: 100%;
        }
      }
      .insurecomp-logo-container {
        height: 3.28rem;
        margin-top: -1.4rem;
        img {
          max-height: 100%;
        }
      }
    }
  }
}

.about-section {
  font-family: "Optima";
  font-weight: 400;
  line-height: 1.1;
  .strip {
    word-break: break-word;
    background-color: #145e96;
    .strip-text {
      font-size: 2.4rem;
      color: white;
      padding: 1rem 0;
    }
    .strip-item {
      font-size: 2.4rem;
      color: white;
      padding: 1rem 0;
      flex-basis: 33.33%;
      flex-grow: 0;
    }
  }
  word-break: break-word;
  .about-summary-container {
    margin-bottom: 10rem;
    padding: 0 3rem;
    .about-affinity,
    .our-partners {
      flex-basis: 50%;
      flex-grow: 0;
      font-size: 1.4rem;
    }
    .about-affinity-header,
    .our-partners-header {
      font-size: 2.4rem;
      margin-bottom: 4.5rem;
    }
    .our-partners {
      .our-partners-images {
        .insurecomp-logo {
          width: 10rem;
        }
        .ipeo-logo {
          width: 15rem;
        }
      }
    }
  }
  .video {
    margin-bottom: 10rem;
  }
  .services {
    margin-bottom: 10rem;
    color: white;
    background-color: #145e96;
    padding: 5rem 0;
    .services-header {
      font-size: 2.4rem;
      margin-bottom: 4.5rem;
    }
    .service-item {
      font-size: 1.5rem;
      flex-basis: 21%;
      flex-grow: 0;
      display: flex;
      // flex-direction: column;
      align-items: center;
      // justify-content: space-between;
      img {
        width: 6rem;
        height: 6rem;
      }
      .service-item-text {
        padding: 0 1rem;
        word-break: keep-all;
      }
    }
  }
  .industries-we-serve {
    margin-bottom: 0 !important;
    background-image: url("../images/who-we-serve-bg.jpg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    padding: 5rem 0 !important;
    background-color: rgba(15, 8, 1, 0.79) !important;
    /* Tint color */
    background-position: center;
    background-blend-mode: multiply;
    .industries-we-serve-header {
      font-size: 2.4rem;
      margin-bottom: 4.5rem;
    }
    .industry-item {
      color: white;
      font-size: 1.5rem;
      flex-basis: 25%;
      flex-grow: 0;
      display: flex;
      // flex-direction: column;
      align-items: center;
      // justify-content: space-between;
      .service-item-description {
        font-weight: 500 !important;
      }
      .service-item-header {
        font-weight: 800 !important;
      }
      img {
        width: 6rem;
        // height: fit-content;
      }
    }
  }
}

.get-quote {
  button {
    font-family: "Poppins";
    font-weight: 500 !important;
  }
}

.page-footer {
  font-family: "Optima";
  a {
    color: black;
  }
  a:hover {
    text-decoration: none;
  }
  .upper-footer {
    padding: 3rem 2.5rem;
    .section-1 {
      grid-gap: 1rem;
      flex-wrap: wrap;
      min-width: fit-content;
      .partners-header {
        font-weight: 500;
        text-transform: uppercase;
        font-size: 16px;
        line-height: 1.5;
      }
      .partners-image-container {
        grid-gap: 2rem;
        flex-wrap: wrap;
        .partners-image {
          .ipeo-logo {
            height: 60px;
          }
          .insurecomp-logo {
            height: 70px;
          }
        }
      }
      @media screen and (max-width: 1179px) {
        min-width: auto;
        .partners-image-container {
          grid-gap: 0;
        }
      }
      @media screen and (max-width: 825px) {
        .partners-image-container {
          grid-gap: 2rem;
        }
        .partners-header {
          text-align: center;
        }
      }
      @media screen and (max-width: 546px) {
        .partners-image-container {
          justify-content: center;
        }
      }
    }
    .section-2 {
      justify-content: center;
      color: black;
      grid-gap: 4rem;
      font-size: 1.4rem;
      .footer-item {
        cursor: pointer;
      }
      .footer-item:not(:last-child) {
        margin-bottom: 15px;
      }
      .get-quote {
        width: 100%;
        button {
          border-radius: 7.2px;
          background-color: #ffd13f;
          border-color: #eea236;
          box-shadow: 2px 4px 12px #eea236;
          color: #fff;
          font-size: 1rem;
          font-weight: 800;
          word-break: break-word;
          padding: 0.6rem 0.7rem;
        }
      }
    }
    .section-3 {
      min-width: fit-content;
      .footer-item {
        width: 100%;
      }
      .socials {
        justify-content: center;
        grid-gap: 2rem;
        .social-icon {
          height: 1.6rem;
          width: 1.6rem;
        }
      }
    }
    .section-4 {
      font-weight: 500;
      font-size: 16px;
      line-height: 1.5;
    }
  }
  .bottom-footer {
    color: white;
    font-size: 1rem;
    padding: 0.5rem 0;
  }
}

.home3,
.demo-container {
  font-weight: 400;
  line-height: 1.1;
  h1 {
    font-family: "Poppins";
    font-weight: 800;
    font-size: 64px;
    letter-spacing: 0px;
    color: #487ebc;
    // background: -webkit-linear-gradient(#0e4975, #4baeff);
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
  }
  .header {
    border-bottom: solid #0000006b;
    border-width: 1.5px;
    .demo {
      border-radius: 7.2px;
      color: #faa54e;
      font-size: 1rem;
      font-weight: 800;
      padding: 0.6rem 0.7rem;
      box-shadow: 0px 0px 0px 3px #faa54e inset;
    }
    .logos-container {
      .get-quote {
        button {
          box-shadow: none;
          border: none;
          background-color: #ffc203;
          font-family: "Poppins";
        }
      }
    }
  }
  .demo:hover {
    background-color: #faa54e;
    color: #ffffff;
    padding: 0.65rem 0.75rem;
  }

  h2 {
    font-family: "Poppins";
    font-weight: 800;
    text-align: center;
    font-size: 48px;
    margin-bottom: 50px;
    color: #487ebc;
    // background: -webkit-linear-gradient(#0e4975, #4baeff);
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
  }
  @media screen and (max-width: 768px) {
    h1 {
      font-size: 48px;
    }
    h2 {
      font-size: 36px;
    }
  }
  @media screen and (max-width: 576px) {
    h1 {
      font-size: 36px;
    }
    h2 {
      font-size: 28px;
    }
  }
  .our-partners {
    margin: 100px auto;
    padding: 60px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 30px 60px;
    border-radius: 15px;
    // max-height: 120px;
    max-width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    // gap: 50px;
    .partners-title {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .partners-image {
      img {
        height: 100px;
        width: auto;
      }
    }
    h3 {
      font-size: 2.5rem;
      font-family: "Poppins";
      font-weight: 700;
      color: #487ebc;
      text-align: center;
    }
    @media screen and (max-width: 1190px) {
      .partners-image {
        img {
          height: 80px;
        }
      }
    }
    @media screen and (max-width: 1030px) {
      .partners-image {
        img {
          height: 70px;
        }
      }
      h3 {
        font-size: 2.2rem;
      }
    }
    @media screen and (max-width: 950px) {
      .partners-image {
        img {
          height: 60px;
        }
      }
      h3 {
        font-size: 2rem;
      }
    }
    @media screen and (max-width: 840px) {
      .partners-image {
        img {
          height: 50px;
        }
      }
      h3 {
        font-size: 1.8rem;
      }
    }
    @media screen and (max-width: 750px) {
      flex-direction: column;
      gap: 20px;
      // .partners-image {
      //   img {
      //     height: 70px;
      //   }
      // }
      // h3 {
      //   font-size: 2.2rem;
      // }
    }
    @media screen and (max-width: 576px) {
      flex-direction: column;
      max-width: 80%;
      max-height: none;
      gap: 20px;
      .partners-image {
        img {
          height: 40px;
        }
      }
      h3 {
        font-size: 24px;
      }
    }
  }
  .about-section {
    .about-summary-container {
      .about-affinity-text {
        font-family: "Poppins";
        font-weight: 300;
        font-size: 20px;
        line-height: 1.8;
        max-width: 800px;
        margin: 0 auto;
        @media screen and (max-width: 576px) {
          font-size: 16px;
          text-align: center !important;
        }
      }
    }
    .services {
      margin-bottom: 10rem;
      background-color: white;
      color: black;
      // background-color: #145e96;
      padding: 0rem 0;
      .services-header {
        font-size: 2.4rem;
        margin-bottom: 4.5rem;
      }
      .services-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 30px;
        .service-item {
          box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.2);
          border-radius: 20px;
          padding: 30px;
          font-size: 1.5rem;
          flex-basis: 38%;
          flex-grow: 0;
          display: flex;
          // flex-direction: column;
          align-items: start;
          // justify-content: space-between;
          .service-item-image {
            background-color: #145e96;
            // background: -webkit-linear-gradient(#0e4975, #4baeff);
            border-radius: 1.5rem;
            padding: 12px;
            // height: 3rem;
            // width: 3rem;
            margin-right: 1rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            img {
              width: 1.5rem;
              height: 1.5rem;
            }
          }
          .service-item-text {
            padding: 0;
            word-break: keep-all;
            text-align: left;
            font-family: "Poppins";
            font-size: 16px;
            line-height: 1.5;
            // margin-top: 20px;
            font-weight: 500;
            opacity: 0.8;
            .service-item-description {
              font-size: 13px;
              font-weight: 300;
            }
          }
        }
        @media screen and (max-width: 1200px) {
          flex-wrap: wrap;
          .service-item {
            min-width: 35%;
          }
        }
        @media screen and (max-width: 768px) {
          .service-item {
            min-width: 40%;
          }
        }
        @media screen and (max-width: 576px) {
          .service-item {
            min-width: 80%;
          }
        }
      }
    }
    .industries-we-serve {
      .services-container {
        .service-item {
          .service-item-image {
            background-color: inherit;
            padding: 0;
            img {
              width: 3rem;
              height: 3rem;
            }
          }
        }
      }
    }
  }
  .page-footer {
    font-family: "Poppins";
    .upper-footer {
      // padding-left: 100px;
      // padding-right: 100px;
      .footer-item {
        font-weight: 500;
        text-transform: uppercase;
        font-size: 16px;
        line-height: 1.5;
        // margin-top: 20px;
      }
      .get-quote {
        // justify-content: flex-end !important;
        button {
          font-family: "Poppins";
          box-shadow: none;
          border: none;
          font-size: 1rem;
          background-color: #ffc203;
        }
      }
    }
    .bottom-footer {
      font-size: 14px;
      font-weight: 300;
      background-color: #0a2e4b;
    }
    @media screen and (max-width: 1024px) {
      .upper-footer {
        // padding-left: 20px;
        // padding-right: 20px;
        // flex-wrap: wrap;
        justify-content: center;
        .footer-item {
          font-size: 14px;
          // margin-left: 10px;
          // margin-right: 10px;
        }
      }
    }
  }
  .list-video-row {
    height: 340px;
    width: 100%;
    margin-top: 2rem;
    .col- * {
      padding: 5px;
    }
    p {
      margin-left: 1rem;
      font-size: 30px;
    }
    iframe {
      width: 100%;
      height: 100%;
    }
  }
  .footer {
    width: 100%;
    height: 3rem;
    background-color: #183650;
    font-size: 2rem;
    a,
    span {
      margin-right: 2.5rem;
      color: white;
    }
    span:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .home-form {
    background-color: #e4eaef;
    .formTitle {
      text-align: center;
      font-weight: 500;
    }
    form {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-flow: column;
    }
    input::placeholder {
      color: #9a9c9e71;
    }
    input {
      width: 80%;
      color: #858d94;
      height: 50px;
      font-size: x-large;
      margin-bottom: 25px;
      border: none;
      font-weight: 500;
      font-family: sans-serif;
    }
    div[class^="col-"] {
      display: flex;
      justify-content: center;
    }
    .btn {
      width: 200px;
      font-size: 20px;
      font-family: sans-serif;
    }
  }
  .minReq {
    background-color: #e4eaef;
    padding: 1.5rem 7rem;
    b {
      font-weight: 700;
      margin-bottom: 20px;
    }
    i {
      color: #5d5d5d;
      font-size: 40px;
    }
    ul {
      margin-top: 20px;
      font-family: sans-serif;
      font-size: 30px;
      font-weight: 400;
    }
  }
  .whyWorkComp {
    padding: 1.5rem 7rem;
    b {
      font-weight: 700;
    }
    .row {
      margin-top: 20px;
    }
    div[class^="col-"] {
      display: flex;
      justify-content: start;
    }
    img {
      margin-right: 10px;
    }
    p {
      font-size: 30px;
    }
  }
  .header-container {
    position: sticky;
    top: 0;
    z-index: 10;
    .header {
      width: 100%;
      .logos-container {
        font-size: 15px;
        padding: 0.4rem 0;
        margin-left: 0.5rem;
        margin-right: 2.5rem;
        .get-quote {
          justify-content: flex-end !important;
        }
        .wcn-logo-container {
          height: 6.65rem;
          img {
            max-height: 100%;
          }
        }
        .insurecomp-logo-container {
          height: 3.28rem;
          margin-top: -1.4rem;
          img {
            max-height: 100%;
          }
        }
      }
    }
  }
  .image-block {
    cursor: pointer;
    position: relative;
    height: 30rem;
    width: 100%;
    overflow: hidden;
    .image-text-container {
      padding: 1rem;
      background: rgba(0, 0, 0, 0.356);
      position: absolute;
      text-align: center;
      word-break: break-word;
      padding-inline: 10%;
      .image-text {
        font-size: 3.125rem;
        font-weight: bolder;
        color: white;
        text-align: center;
      }
    }
  }
  .stat-2-1 {
    margin-right: 2.5rem;
    word-break: break-word;
    span {
      width: inherit;
      font-weight: bolder;
      font-size: 2.5rem;
    }
    img {
      height: 5.5rem;
      max-height: 100%;
      max-width: 100%;
    }
  }
  .quote-button-container {
    margin-bottom: 10rem;
  }
  .get-quote {
    width: 100%;
    button {
      border-radius: 7.2px;
      background-color: #ffd13f;
      color: #fff;
      font-size: 1.4rem;
      font-weight: 800;
      padding: 0.6rem 0.7rem;
    }
    button:hover {
      background-color: #ffc203;
    }
  }
  .confirm-quote {
    width: 100%;
    button {
      border-radius: 10px;
      box-shadow: 2px 4px 10px #23b4bd;
      font-size: 3.125rem;
      font-weight: 700;
      color: white;
      background-color: #23b4e8;
      width: 35rem;
      padding: 0.5rem 0;
    }
  }
  .stats-banner {
    background-color: #183650;
    padding: 2.5rem 0;
    .stats-banner-item-1,
    .stats-banner-item-2,
    .stats-banner-item-3 {
      flex: 1;
      font-size: 2.5rem;
      font-weight: bolder;
      color: white;
    }
  }
  .testimonials {
    font-size: 2rem;
    margin: 2rem;
    .testimonials-text {
      font-style: italic;
    }
  }
}

class-desc-select .class-desc-select__menu {
  height: fit-content;
  width: fit-content;
  max-width: 500px;
  min-width: -webkit-fill-available;
  min-width: -moz-available;
}

.contentHolder-history {
  min-height: calc(100vh - 15.75rem);
}

@mixin cmprQts {
  //style of the background image:
  background-image: url("../images/backimage4.png");
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 95%;
  background-attachment: sticky;
  .quote-table {
    .primary-table-quote {
      border-spacing: 0 15px;
      border-collapse: separate;
      tbody {
        width: 100%;
        overflow-y: scroll;
        .quote-row {
          ul {
            margin: 0;
            font-size: 12px;
          }
          input[type="checkbox"] {
            width: 18px !important;
            height: 23px !important;
          }
          .peo-logo {
            width: 4rem;
          }
          .teap {
            font-size: 17px;
          }
          .quote-status {
            font-weight: bold;
            // padding-bottom: 10px;
          }
          .work-comp-table,
          .payroll-services-table {
            td {
              padding: 0;
              margin: 0;
            }
          }
          background-color: #ffffff;
          td {
            // margin: 8px;
            // padding-left: 10px;
            // padding-top: 10px;
            vertical-align: top;
            tbody {
              td {
                padding-top: 0;
              }
            }
          }
        }
      }
    }
    .quote-table-company-name {
      padding-right: calc(2rem + 25px);
    }
    .head-row {
      align-items: center;
      justify-content: space-between;
    }
    .hr1 {
      color: #2c2e33;
      font-size: 26px;
      font-weight: 500;
      &.quote-table-company-name {
        // font-family: "Overpass", sans-serif;
        font-size: 20px;
        font-weight: 600;
      }
    }
    .hr2 {
      color: #2c2e33;
      width: 70%;
      margin-top: 1rem;
      margin-bottom: 1rem;
      span {
        color: #5f636b;
      }
    }
    .hr3 {
      margin-bottom: 1.5rem;
      color: #2c2e33;
    }
    .body-row {
      padding-left: 6px;
      margin-top: 2rem;
      // justify-content: left;
      .table {
        margin-bottom: 7rem;
        position: relative;
        right: 2rem;
        border-collapse: separate;
        border-spacing: 25px 0px;
        thead {
          th {
            color: #2c2e33;
            position: relative;
            right: 0.6rem;
            font-size: 16px;
          }
        }
        tbody {
          padding-left: 5px;
          padding-right: 5px;
          font-size: 18px;
          td,
          th {
            width: 25%;
            border: 1px solid white;
            background-color: white;
            color: #2c2e33;
            font-size: 18px;
            font-weight: normal;
            div {
              height: 100%;
              label {
                margin-bottom: 0;
              }
            }
          }
          td {
            label {
              font-size: 22px;
              font-weight: 800;
              color: #1463ac;
              margin-bottom: 0;
            }
            .best {
              font-size: 14px;
              margin-left: 1rem;
              color: #038b00;
            }
            .higher {
              font-size: 14px;
              margin-left: 1rem;
              color: #2c2e33;
            }
            .uw {
              font-size: 14px;
              margin-left: 1rem;
            }
            input {
              margin: 0.5rem;
            }
          }
        }
      }
    }
  }
}

.new-navbar {
  margin-bottom: 1rem;
  height: 4.75rem;
  background-color: #003764;
  color: white;
  font-weight: 600;
  font-size: 16px;
  .pride-logo {
    margin-left: 2.2rem;
  }
  .insurecomp-logo {
    margin-right: 2.2rem;
  }
  .forBig {
    margin-left: auto;
  }
  .new-logo {
    // margin-top: 0.5rem;
    height: 3.375rem;
    // margin-left: 2.2rem;
  }
  .boxed-logo {
    background-color: #fff;
    padding: 20px;
    height: 125px;
    margin: 5px 20px;
    border-radius: 5px;
    -webkit-box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
    margin-top: 20px;
    margin-left: 40px;
  }
  button {
    border: none;
    color: white;
    background: none;
    font-weight: 600;
  }
  span {
    color: white;
    font-weight: 600;
  }
  ul {
    li {
      // padding-bottom: 10px;
      margin-right: 1rem;
      margin-left: 1rem;
    }
  }
}

.secDesc {
  text-align: center;
  font-size: 16px;
  margin-bottom: 30px;
}

#small-loader {
  width: fit-content;
  height: fit-content;
  /* margin-top: 1rem; */
  > img {
    width: 2rem;
  }
}

#rate1,
#rate2 {
  margin-bottom: 5px;
}

#compareQuote {
  .notification-text {
    font-size: 11px;
  }
  .btnSubmits {
    margin-top: 2rem;
    width: 210px;
    height: 40px;
    border-radius: 5px;
    background-color: #ffd13f;
    border-color: #eea236;
    box-shadow: none;
    color: white;
    // font-size: 14px;
    font-weight: 800;
  }
  .btnView {
    width: 210px;
    height: 40px;
    border-radius: 5px;
    background-color: #1463ac;
    margin-right: 1rem;
    margin-top: 2rem;
    // font-size: 14px;
    font-weight: 800;
  }
  // .iFrameContainer {
  //   width: 100%;
  //   height: 700px;
  //   margin-top: 1rem;
  //   justify-content: center;
  //   margin-bottom: 3.5rem;
  //   iframe {
  //     width: 100%;
  //     height: 100%;
  //   }
  // }
}

.errMsg {
  color: red;
  font-family: MainFont, Arial, Georgia, sans-serif !important;
}

.Admin-Dashboard {
  .discount-body {
    input,
    div {
      font-size: 12px;
    }
  }
}

.header {
  background-color: white;
  width: 100%;
  .logos-container,
  .info-container {
    a {
      color: #212529;
    }
    a:hover {
      text-decoration: none;
    }
    .get-quote {
      button {
        border-radius: 7.2px;
        background-color: #ffd13f;
        // border-color: #eea236;
        box-shadow: 2px 4px 12px #eea236;
        color: #fff;
        font-size: 1rem;
        font-weight: 800;
        padding: 0.6rem 0.7rem;
      }
    }
    padding: 0.4rem 0;
    margin-left: 0.5rem;
    margin-right: 2.5rem;
    display: flex;
    justify-content: space-between;
    .wcn-logo-container {
      height: 6.65rem;
      img {
        max-height: 100%;
      }
    }
    .insurecomp-logo-container {
      height: 2.47rem;
      margin-top: -1rem;
      img {
        max-height: 100%;
      }
    }
  }
}

.stat-2-1 {
  margin-right: 2.5rem;
  word-break: break-word;
  span {
    width: max-content;
    font-weight: bolder;
    font-size: 1rem;
  }
  img {
    height: 5.5rem;
    max-height: 100%;
    max-width: 100%;
  }
}

.newquote-mainbody {
  // font-family: "Rokkitt", serif;
  //global styles for quote main body
  background-color: #e6edf0;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  .forSmall {
    display: none;
  }
  .upload-docs-action {
    border-radius: 7.2px;
    background-color: #ffd13f;
    border-color: #eea236;
    box-shadow: 2px 4px 12px #eea236;
    color: white;
    font-size: 1rem;
    font-weight: 800;
  }
  .newquote-content {
    //global styling for everything in the light blue background
    padding-left: 1.5rem;
    padding-right: 1.6rem;
    width: 90%;
    margin-top: 1rem;
    padding-bottom: 2rem;
    background-color: #e2f2ff;
    .emodDate,
    form select,
    form input[type="text"],
    form input[type="email"],
    form input[type="password"],
    form input[type="date"],
    form textarea {
      width: 100%;
      // font-size: 0.85em;
      border-radius: 4px;
      border: 1px solid white;
      background: white;
      color: #2b2d31;
      font-weight: "normal";
    }
    input[type="checkbox"] {
      height: 16px !important;
      width: 16px;
      border-radius: 4px;
      border: solid 1px #979797;
      background-color: #ffffff;
    }
    //styling of the headers and section-headers:
    // this is styling for all the different stages
    // for changing any heading, please refer to this section only
    .formTitle {
      color: #2c2e33;
      // font-family: "Rubik", sans-serif;
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 1.38;
      &.location-details-heading {
        font-weight: 400;
        font-size: 1.2rem;
      }
      &.compName {
        // font-family: Overpass;
        font-size: 20px;
        font-weight: 600;
        color: #2c2e33;
        // padding-left: 4rem;
      }
      &.title2 {
        width: 70%;
        display: inline-block;
      }
      &.title3 {
        font-size: 20px;
        font-weight: normal;
      }
    }
    .addressRate,
    .aggregate-heading,
    .stateAggName {
      // font-family: Overpass;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #2b2d31;
    }
    //end of heading/subheading styles
    //styling of buttons:
    .transpButton {
      background-color: transparent;
      border-color: transparent;
    }
    .btnView {
      width: 210px;
      height: 40px;
      border-radius: 5px;
      background-color: #1463ac;
      margin-right: 1rem;
      margin-top: 2rem;
      // font-size: 14px;
      font-weight: 800;
    }
    .proceed-bind,
    .upload-docs {
      border-radius: 7.2px;
      background-color: #ffd13f;
      border-color: #eea236;
      // background-image: linear-gradient(to right, #10c03e, #0eaf84);
      // box-shadow: none;
      box-shadow: 2px 4px 12px #eea236;
      // border-color: transparent;
      color: white;
      font-size: 1rem;
      font-weight: 800;
    }
    .btnSubmits {
      margin-top: 2rem;
      border-radius: 7.2px;
      background-color: #ffd13f;
      border-color: #eea236;
      box-shadow: 2px 4px 12px #eea236;
      color: white;
      font-size: 1rem;
      font-weight: 800;
    }
    // end of button styling
    //style for the progress bar at the top of every page
    // please refer to this section only for styling the progress bar
    .color-bar {
      margin-top: 1.5rem;
      margin-bottom: 44px;
      // margin-left: 1.2rem;
      width: 100%;
      height: 12px;
      border-radius: 8px;
      background-image: linear-gradient(to right, #7d8fec, #2fe6db);
      span {
        display: block;
        height: 100%;
        background-color: #0dd209;
        border-radius: 8px;
      }
    }
    // end of progress bar styles
    //style for company profile tab
    #paymentQuote {
      span.errMsg {
        color: red;
        font-size: 11px;
      }
    }
    #compProfile {
      // background-image: url("../images/backimage1.png");
      background-repeat: no-repeat;
      background-position-x: 95%;
      background-position-y: 95%;
      background-attachment: sticky;
      .react-datepicker-wrapper {
        width: -webkit-fill-available;
        width: -moz-available;
        width: stretch;
        .react-datepicker__input-container {
          width: 100%;
        }
      }
      #healthQues,
      #otp {
        font-size: inherit;
      }
      #claimTable {
        .form-group {
          margin-bottom: 2px;
        }
        tr {
          width: 100%;
        }
        input {
          height: 36px;
          margin-bottom: "none";
          color: #2b2d31;
          font-size: 14px;
        }
        width: 100%;
        color: #919399;
        font-weight: "normal";
        border-collapse: separate;
        border-spacing: 20px 0px;
        .row-no-coverage {
          .no-coverage {
            width: 30%;
            margin-bottom: 20px;
            input {
              height: 12px;
              margin-bottom: 25px;
              margin-right: 5px;
            }
          }
        }
      }
      .formTitle {
        margin-bottom: 34px;
      }
      .company-locations,
      .emod-ques-heading {
        margin-bottom: 30px;
      }
      .mandate {
        color: #919399;
      }
      small {
        color: #333333;
      }
      .form-group {
        margin-bottom: 38px;
        select,
        input {
          height: 35px;
          color: #2b2d31;
          // font-size: 14px;
          padding-left: 5px;
        }
        label {
          -webkit-line-clamp: 1;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          // font-size: 11px;
          color: #333;
          margin-bottom: 0;
          &.mandatory::after {
            content: "*";
            padding-left: 2px;
          }
        }
        p.errMsg {
          color: red;
          font-size: 11px;
        }
      }
      textarea {
        // padding-left: 5px;
        height: 123px;
      }
      #allLocations {
        margin-top: 6px;
      }
      .emod-ques {
        margin-top: 44px;
      }
      .address-delete {
        margin-top: 1.2rem;
      }
      .linkLike {
        position: relative;
        bottom: 20px;
      }
      #otpSendMsg,
      #otpSentMsg,
      #otpVerify,
      #verifySuc {
        display: none;
        font-size: 0.8em;
      }
      #otpSec,
      #resendOTP {
        display: none;
      }
      #emailStatus,
      #verifyStatus {
        margin-top: 1rem;
      }
      #timerOTP {
        font-size: 0.8rem;
      }
      .btn-link {
        padding-left: 0;
      }
    }
    #cmprRate {
      //style block for compare rate page/tab
      //styling for the background image
      background-image: url("../images/backimage2.png");
      background-repeat: no-repeat;
      background-position-x: 95%;
      background-position-y: 100%;
      background-attachment: sticky;
      // .download-link-div {
      //   width: 20px;
      // }
      .formTitle {
        margin-bottom: 60px;
      }
      .title3 {
        margin-bottom: 25px;
      }
      .linkLike {
        margin-bottom: 37px;
      }
      .calcPremium {
        color: #919399;
        input {
          height: 36px;
          color: #2b2d31;
          font-size: 14px;
          padding-right: 5px;
        }
      }
      .rangeSlider {
        padding: 1.2rem;
        .row {
          // justify-content: space-around;
          margin: 0;
          width: 100%;
        }
        .premVal {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 35%;
          height: 35px;
          border-radius: 20px;
          background-color: #2987de;
          font-size: 20px;
          font-weight: 800;
          color: #ffffff;
          span {
            position: relative;
            top: 2px;
          }
        }
        .barBG {
          margin-left: 1rem;
          margin-right: 1rem;
          margin-top: 1rem;
          width: 12px;
          height: 2px;
          background-color: #2c2e33;
        }
      }
      #attachSec {
        margin-top: 47px;
        .no-lossrun-affidavit-heading {
          color: #2b2d31;
          font-weight: "normal" !important;
          font-size: 14px;
        }
        .dropped-file {
          color: #4169ba;
        }
        .drop-zone {
          margin-top: 20px;
          margin-bottom: 44px;
          display: flex;
          background: white;
          opacity: 0.5;
          justify-content: center;
          align-items: center;
          height: 106px;
          width: 385px;
          border: 1px dashed black;
          span {
            color: #1463ac;
          }
        }
        .drop-info {
          margin-top: 20px;
        }
      }
      .grey-table-row {
        position: relative;
        right: 7px;
      }
      #claimTable {
        input {
          height: 36px;
          margin-bottom: "none";
          color: #2b2d31;
          font-size: 14px;
        }
        color: #919399;
        font-weight: "normal";
        border-collapse: separate;
        border-spacing: 20px 0px;
        .row-no-coverage {
          .no-coverage {
            width: 30%;
            margin-bottom: 20px;
            input {
              height: 12px;
              margin-bottom: 25px;
              margin-right: 5px;
            }
          }
        }
      }
      .btnSubmits {
        margin-top: 30px;
        margin-bottom: 36px;
      }
      .form-group {
        select,
        input {
          height: 35px;
          color: #2b2d31;
          // font-size: 14px;
          padding-left: 5px;
        }
        label {
          -webkit-line-clamp: 1;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          // font-size: 11px;
          color: #333;
          margin-bottom: 0;
          &.mandatory::after {
            content: "*";
            padding-left: 2px;
          }
        }
        p.errMsg {
          color: red;
          font-size: 11px;
        }
      }
      #claimTable {
        .form-group {
          margin-bottom: 2px;
        }
      }
    }
    #undrQues {
      // style for the background image:
      // background-image: url("../images/backimage3.png");
      background-repeat: no-repeat;
      background-position-x: 95%;
      background-position-y: 95%;
      background-attachment: sticky;
      //style for the unerwriter questions and additional questions table:
      .heading-row {
        justify-content: space-between;
        .compName {
          margin-top: 2px;
          color: #2c2e33;
          font-size: 20px;
          font-weight: 600;
        }
      }
      .title2 {
        margin-bottom: 50px;
      }
      .table-addnques,
      .table-undrques {
        margin-bottom: 3.5rem;
        thead {
          color: #2c2e33;
          font-size: 1.2rem;
          tr {
            th {
              border: none;
            }
          }
        }
        tbody {
          tr {
            td {
              vertical-align: middle;
              padding-top: 5px;
              padding-bottom: 5px;
              &.tableAns {
                .radio-btn-no {
                  margin-left: 2.5rem;
                  width: 18px;
                  height: 18px;
                }
                .radio-btn-yes {
                  margin-left: 1rem;
                  width: 18px;
                  height: 18px;
                }
                .radiobtn {
                  margin-left: 4px;
                  position: relative;
                  bottom: 4px;
                }
              }
              &.tableQues {
                width: 60%;
              }
              &.tableRemarks {
                padding-right: 5px;
                input {
                  height: 40px;
                  border: 1px solid rgba(36, 181, 233, 1);
                  border-radius: unset;
                }
                .hidden {
                  background-color: rgba(131, 165, 191, 0.2);
                }
              }
              &.table-question {
                margin: 10px;
              }
            }
            &:nth-child(odd) {
              // margin-left: -20px;
              background-color: #ffffff;
              opacity: 1;
              .tableRemarks {
                background: #e2f2ff;
              }
            }
          }
        }
      }
    }
    .additional-questions {
      margin-bottom: 30px;
    }
    .table-undrques {
      margin-bottom: 2rem;
      tbody {
        .trow1 {
          padding-top: 5px !important;
          padding-bottom: 5px !important;
        }
      }
    }
    .table-addnques {
      .tableAns {
        width: 18%;
      }
    }
    .btn-undrques {
      margin-bottom: 2rem;
    }
    #cmprQts {
      @include cmprQts;
    }
    //styling of review and pay page:
    #payBind {
      input,
      select {
        height: 40px;
      }
      .formTitle {
        margin-bottom: 37px;
      }
      #carrierName {
        font-size: 16px;
      }
      background-image: url("../images/backimage5.png");
      background-repeat: no-repeat;
      background-position-x: 95%;
      background-position-y: 95%;
      background-attachment: sticky;
      .row-formTitle {
        justify-content: space-between;
        .formTitle {
          margin-bottom: 0rem;
        }
      }
      .row-acord-preview-heading {
        justify-content: space-between;
        .col-acord-text {
          margin-left: 16px;
        }
        .col-acord-download {
          margin-left: 15px;
          padding-left: 36px;
        }
        #accordLink {
          color: #0973d5;
        }
        img {
          margin-right: 1rem;
        }
      }
      .row-acord-preview {
        margin-top: 1rem;
        justify-content: center;
        margin-bottom: 3.5rem;
        iframe {
          border: none;
          width: 78%;
          height: 30rem;
        }
      }
      .pdf-upload-placeholder {
        width: 78%;
        height: 30rem;
        margin-top: 2rem;
        justify-content: center;
        align-items: center;
        margin-bottom: 81px;
        .pdf-loader-gif {
          margin-left: 1.5rem;
        }
      }
      .row-tnc {
        margin-top: 1rem;
        font-size: 16px;
        color: #2c2e33;
        p {
          margin-top: 0.6rem;
        }
        .payPageItems2 {
          margin-left: 2rem;
        }
        .linkLike {
          margin-left: 0.5rem;
        }
      }
    }
    //styling for bind page
    #binderForm {
      .linkLike {
        margin-top: 60px;
        margin-bottom: 36px;
      }
      .btnGen {
        margin-bottom: 10px;
      }
      .col-sm-12 {
        .formTitle {
          margin-bottom: 1rem;
        }
        .row-binder-heading {
          padding-right: 10px;
          padding-left: 10px;
          justify-content: space-between;
        }
        .row-iframe {
          justify-content: center;
          width: 100%;
          .col-iframe {
            padding-left: 25px;
            padding-right: 25px;
            width: 100%;
            .acord {
              justify-content: flex-end;
              width: 95%;
              color: #0973d5;
              span {
                position: relative;
                top: 4px;
                margin-left: 0.5rem;
              }
            }
            .row-iframe-preview {
              width: 100%;
              justify-content: center;
              iframe {
                // margin-top: 2rem;
                border: none;
                width: 90%;
                height: 45rem;
              }
            }
          }
        }
      }
    }
    .class-desc-select {
      background-color: white;
      div {
        height: 35px;
        padding-top: 0;
        padding-bottom: 0;
      }
      .class-desc-select__indicator {
        align-items: center;
      }
      .class-desc-select__menu {
        height: fit-content;
        width: fit-content;
        max-width: 350px;
        min-width: -webkit-fill-available;
        min-width: -moz-available;
        .class-desc-select__menu-list {
          height: fit-content;
          max-height: 150px;
          .class-desc-select__option {
            display: flex;
            align-items: center;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: fit-content;
          }
        }
      }
      .class-desc-select__placeholder {
        margin: 0;
        position: unset;
        transform: none;
        height: fit-content;
      }
      .class-desc-select__control {
        // min-height: 35px;
        // border: 1px solid #ababab;
        // background: transparent;
        // border-radius: 4px;
        .class-desc-select__value-container {
          overflow: initial;
          .class-desc-select__single-value {
            height: fit-content;
          }
        }
      }
    }
    .locationName {
      span {
        font-size: 16px;
        font-weight: normal;
        color: #2b2d31;
      }
    }
    #extraInput {
      margin-top: 5px;
    }
    .check {
      margin-right: 0.3rem;
    }
    #newAddress {
      display: none;
      margin-left: 0;
    }
    .payPageItems {
      margin-left: 1rem;
    }
    .linkLike {
      position: relative;
      right: 0.55rem;
      // margin-top: 68px;
      // margin-bottom: 63px;
    }
    // // styl for binder
    .green {
      margin-left: 25%;
      font-size: 22px;
      font-weight: 600;
      color: #138f11;
      margin-bottom: 16px;
      p {
        margin: 0;
      }
    }
    .endForm {
      margin-left: 2rem;
    }
  }
  //styling for review and pay
  //paybind is the main container of review and pay page:
  // styles of side tab
  .newquote-sidebar {
    width: 23.5%;
    // max-width: 50%;
    padding-left: 0.2rem;
    background-color: #ffffff;
    margin-top: 1rem;
    padding-bottom: 2rem;
    margin-right: 1rem;
    // font-family: "Overpass", sans-serif;
    .nav-item {
      outline: none;
      border-radius: none;
      .tick {
        margin-right: 0.08rem;
        margin-top: 1rem;
        float: right;
      }
      .nav-link {
        border: none;
        margin-bottom: 1rem;
        color: #2c2e33;
        // font-size: 16px;
        font-weight: 800;
        margin-left: 0.5rem;
        &.active {
          color: #0068c9;
          border: none;
        }
        .sidebar-stagename {
          margin-left: 1.2rem;
        }
        &.sidebar-heading {
          margin-top: 30px;
          color: #6b6666;
        }
        .disabled {
          display: none;
        }
      }
    }
  }
}

#downloadable {
  width: 90%;
  margin: auto;
  padding-top: 20px;
  font-size: 14px;
  strong {
    font-size: 16px;
  }
}

#downloadable {
  .sectionHolder {
    padding: 20px;
    margin: 30px auto;
  }
}

.home-page {
  //styling of the new home page
  padding-top: 5rem !important;
  #banMain {
    img {
      width: 100%;
    }
    h2 {
      // font-family: "Merriweather", sans-serif;
      color: #2162ac;
      font-size: 50px;
      font-weight: 600;
      margin-top: 26px;
      margin-bottom: 16px;
    }
    h3 {
      color: #2f3237;
      font-size: 20px;
      line-height: 1.5;
      font-weight: normal;
      margin-bottom: 36px;
    }
    .btn {
      color: white;
      // font-family: "Rubik", sans-serif;
      font-size: 22px;
      font-weight: normal;
      border-radius: 7.2px;
      color: #fff;
      background-color: #ffd13f;
      border-color: #eea236;
      margin-bottom: 30px;
      box-shadow: 2px 4px 12px #eea236;
    }
  }
}

#itWorks {
  margin-right: 0px;
  margin-left: 0px;
  .row-3steps {
    // width: 100%;
    padding: 40px 0 40px 0;
    height: auto;
    background-color: #2162ac;
    // background-color: #2e3952;
    //3 steps logo
    .col-simplesteps {
      color: white;
      text-align: left;
      .simple-steps-wrapper {
        max-width: 300px;
      }
      h2 {
        text-align: left;
        font-size: 200px;
        margin-bottom: 0px;
        line-height: 1;
      }
      h3 {
        font-size: 44px;
        font-weight: 300;
        text-align: left;
      }
      img {
        margin: 1rem;
        position: relative;
        left: 15px;
        display: none;
      }
      .line-below-3 {
        width: 180px;
        height: 4px;
        opacity: 0.5;
        background-color: #ffffff;
        margin-bottom: 1rem;
        margin-top: 10px;
      }
    }
    //3-steps cards
    .col-simplesteps-cards {
      .card {
        width: 90%;
        color: white;
        background-color: transparent;
        .row {
          align-items: center;
          .col-card-image {
            height: auto;
            margin-right: 10px;
            .card-img {
              border: 0px !important;
              width: 97px;
              object-fit: contain;
              // img {
              //   margin-bottom: 35px;
              // }
            }
          }
          .col-card-text {
            height: auto;
            .card-body {
              text-align: left;
              .card-title {
                // font-family: "Rubik", sans-serif;
                font-size: 28px;
                font-weight: 500;
              }
              .card-text {
                margin-top: auto;
                // font-family: "Rubik", sans-serif;
                font-size: 20px;
                line-height: 1.5;
                font-weight: normal;
              }
              .learn-more {
                // font-family: Arial, Helvetica, sans-serif;
                color: #2ed359;
                font-size: 16px;
                font-weight: bold;
              }
              .learn-more-arrow {
                // font-size: 2rem;
                font-weight: bolder;
              }
            }
          }
        }
      }
    }
  }
  .row-our-mission {
    color: white;
    height: 420px;
    background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.4),
      rgba(0, 153, 255, 0.4) 100%
    );
    background-image: url("../images/home-mission-background.jpg");
    .col-md-12 {
      padding: 80px 80px 80px 120px;
      h1 {
        position: relative;
        right: 13px;
        // font-family: "Merriweather", sans-serif;
        font-size: 100px;
        font-weight: bold;
        margin-bottom: 15px;
      }
      h2 {
        // font-family: "Rubik", sans-serif;
        font-size: 24px;
        font-weight: normal;
        line-height: 1.5;
      }
    }
  }
  .row-everything-you-need {
    .col-md-12 {
      padding: 115px;
      h1 {
        color: #353a3f;
        // font-family: "Merriweather", sans-serif;
        font-weight: bold;
        font-size: 44px;
      }
      .card-row {
        .card {
          background-color: #f8f9fb;
          height: auto;
          margin: 20px;
          text-align: center;
          img {
            height: 80px;
            width: auto;
            margin-top: 40px;
            margin-bottom: 30px;
          }
          .card-title {
            color: #33333c;
            // font-family: "Rubik", sans-serif;
            font-size: 20px;
            line-height: 1.3;
            margin-bottom: 2rem;
          }
        }
      }
    }
  }
  .row-video {
    background-color: #e7f4ff;
    height: auto;
    justify-content: center;
    .col-md-12 {
      padding-left: 114px;
      padding-right: 114px;
      padding-top: 100px;
      h1 {
        color: #2c2e34;
        // font-family: "Merriweather", sans-serif;
        font-size: 44px;
        font-weight: bold;
        line-height: 1.27;
      }
      h2 {
        color: #2c2e34;
        font-weight: normal;
        font-size: 20px;
        line-height: 1.5;
        margin-bottom: 40px;
      }
      iframe {
        margin-bottom: 120px;
      }
    }
  }
}

@media only screen and (min-width: 1500px) {
  // body {
  //   background-color: lightblue;
  // }
  // #footerMain {
  //   position: absolute;
  //   bottom: 0;
  // }
}

#footerMain {
  // font-family: "Rubik", sans-serif;
  padding-top: 24px;
  padding-bottom: 20px;
  color: white;
  height: 4.75rem;
  // background-color: #2e3952;
  background-color: #183650;
  padding-left: 100px;
  padding-right: 100px;
  .row-1 {
    div:nth-of-type(6) {
      margin-right: 10px;
    }
    justify-content: space-between;
    ul {
      padding-left: 2px;
      list-style: none;
      font-size: 16px;
    }
    .col-img {
      img {
        height: 75px;
        width: 130px;
      }
    }
    .col {
      a {
        color: white;
        &:hover {
          text-decoration: none;
          color: white;
        }
      }
    }
  }
  .t-and-c-row {
    // opacity: 0.6;
    font-size: 13px;
    line-height: 2.23;
    .col-sm-2 {
      a {
        color: white;
      }
    }
    .dull {
      opacity: 0.6;
    }
    .back-to-top {
      margin-right: 10px;
    }
  }
}

.quote-table {
  .head-row {
    align-items: center;
    justify-content: space-between;
  }
  .hr1 {
    color: #2c2e33;
    font-size: 26px;
    font-weight: 500;
    &.quote-table-company-name {
      // font-family: "Overpass", sans-serif;
      font-size: 20px;
      font-weight: 600;
    }
  }
  .hr2 {
    color: #2c2e33;
    width: 70%;
    margin-top: 1rem;
    margin-bottom: 1rem;
    span {
      color: #5f636b;
    }
  }
  .hr3 {
    margin-bottom: 1.5rem;
    color: #2c2e33;
  }
  .body-row {
    margin-top: 2rem;
    justify-content: center;
    .table {
      margin-bottom: 7rem;
      position: relative;
      right: 1rem;
      border-collapse: separate;
      border-spacing: 25px 0px;
      thead {
        th {
          color: #2c2e33;
          position: relative;
          right: 0.6rem;
          font-size: 16px;
        }
      }
      tbody {
        font-size: 18px;
        td,
        th {
          width: 25%;
          border: 1px solid white;
          background-color: white;
          color: #2c2e33;
          font-size: 18px;
          font-weight: normal;
        }
        td {
          p {
            font-size: 22px;
            font-weight: 800;
            color: #1463ac;
            .best {
              font-size: 14px;
              margin-left: 1em;
              color: #038b00;
            }
            .higher {
              font-size: 14px;
              margin-left: 1rem;
              color: #2c2e33;
            }
          }
          input {
            margin: 0.5rem;
          }
        }
      }
    }
  }
}

#resDrop,
#workDrop,
#quotesDrop,
#profDrop {
  position: relative;
  display: none;
  padding: 0;
}

#resDrop .navDrop,
#quotesDrop .navDrop,
#workDrop .navDrop,
#profDrop .navDrop {
  background: #ffffff;
  position: absolute;
  z-index: 1;
  text-align: left;
  width: 180px;
  top: 10px;
  border-radius: 6px;
  box-shadow: 5px 5px 20px #888888;
}

#quotesDrop .navDrop {
  right: 0px;
  padding: 10px;
}

#profDrop .navDrop {
  width: 200px;
  right: 0px;
}

#profDrop hr {
  margin-top: 0;
  margin-bottom: 0;
}

#profDrop .navDrop li {
  display: inline-block;
  padding: 15px;
  color: #095d98;
  font-size: 1.5rem;
  font-style: initial;
}

#resDrop .navDrop li,
#quotesDrop .navDrop li,
#workDrop .navDrop li {
  display: block;
  color: #095d98;
  padding: 10px;
}

#Dropresources:hover #resDrop,
#Dropworks:hover #workDrop,
#Dropquotes:hover #quotesDrop,
#Dropprofile:hover #profDrop {
  display: block;
}

#Dropprofile:hover #profDrop {
  display: block;
}

#resDrop .navDrop img,
#workDrop .navDrop img {
  width: 30px;
  height: 30px;
  margin-left: 10px;
}

.tab-content {
  // height: 600px;
  min-height: calc(100vh - 9rem);
  display: flex;
  flex-direction: column;
  width: 100%;
}

.image-block {
  cursor: pointer;
  position: relative;
  height: 35rem;
  width: 100%;
  overflow: hidden;
  img {
    max-width: 100%;
  }
  .image-text-container {
    padding: 1rem;
    background: rgba(0, 0, 0, 0.356);
    position: absolute;
    text-align: center;
    word-break: break-word;
    padding-inline: 10%;
    .image-text {
      font-size: 3.125rem;
      font-weight: bolder;
      color: white;
      text-align: center;
    }
  }
}

// .tab-content-login,
// .tab-content-compliance {
//   min-height: calc(100vh - 15.75rem);
// }
// .tab-content-quote {
//   min-height: calc(100vh - 9rem);
// }
#loginButton {
  margin-left: 1rem;
  margin-right: 1rem;
}

#formLogin {
  height: auto;
  width: 450px;
  background: #ffffff;
  margin: auto;
  position: relative;
  padding-top: 20px;
  transition: all 0.5s;
}

#formChange {
  width: 450px;
  background: #ffffff;
  margin: auto;
  position: relative;
  padding-top: 20px;
  transition: all 0.5s;
}

#formReset {
  // min-height: 390px;
  width: 450px;
  background: #ffffff;
  margin: auto;
  position: relative;
  padding-top: 20px;
  transition: all 0.5s;
}

#formReset,
#formLogin,
#formChange {
  border: 1px solid #f9f9f9;
}

#formReset:hover,
#formLogin:hover,
#formChange:hover {
  box-shadow: 0 10px 15px #888888;
  transition: all 0.5s;
}

#lgin {
  /* padding: 150px 0; */
  transition: all 0.5s;
  margin: auto;
}

#chngpwd,
#fgtpwd {
  margin: auto;
}

.forSmall {
  display: none;
}

#loader {
  position: fixed;
  background: rgba(255, 255, 255, 0.9);
  height: 100%;
  width: 100%;
  z-index: 9;
  top: 0;
  left: 0;
  text-align: center;
  display: none;
}

#loader img {
  position: relative;
  width: 80px;
  top: 40%;
  margin: auto;
}

#formReset,
#formChange,
#formLogin {
  // margin-top: -16.5px;
  // margin-bottom: 16px;
  .formTitle {
    font-size: 20px;
  }
  background-color: #e2f2ff;
  form {
    // margin-top: 10px;
    padding: 0 20px;
  }
  form input[type="password"],
  form input[type="email"],
  form input[type="text"] {
    width: 100%;
    font-size: 0.85em;
    border-radius: 4px;
    border: 1px solid white;
    background: white;
    color: #2b2d31;
    font-weight: "normal";
    height: 35px;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  button {
    height: 35px;
    width: 100%;
    border-radius: 5px;
    background-image: linear-gradient(to right, #10c03e, #0eaf84);
    box-shadow: none;
    border-color: transparent;
    color: white;
    font-size: 14px;
    font-weight: 800;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .formBottomLinks {
    // padding: 20px 20px 50px 20px;
    justify-content: space-between;
    a {
      color: #2c2e33;
    }
  }
  .inptSideText {
    color: #5f636b;
  }
}

.formBottomLinks {
  padding: 20px;
}

#changePassForm {
  input[type="text"] {
    width: 100%;
    font-size: 0.85em;
    border-radius: 4px;
    border: 1px solid white;
    background: white;
    color: #2b2d31;
    font-weight: "normal";
    height: 35px;
    margin-bottom: 20px;
    margin-top: 20px;
  }
}

#quoteMain {
  @include cmprQts;
  background-color: #e2f2ff;
  #contentHolder {
    table {
      position: relative;
      right: 32px;
    }
  }
}

#resDrop,
#workDrop {
  .navDrop {
    padding: 0px;
    margin-left: -4rem;
    margin-top: 3rem;
    li {
      color: #0068c9;
      font-size: 13px;
      img {
        margin-left: 0px;
        margin-right: 8px;
        padding-left: 0px;
      }
    }
    a:hover {
      text-decoration: none;
    }
  }
}

.cmprQts-buttons {
  margin-bottom: 10px;
  text-align: center !important;
  // margin-left: 14rem;
}

.btnGen {
  margin-bottom: 36px;
}

#uwPortal {
  .fixedHeader {
    thead > tr > th {
      position: sticky;
      top: -1px;
      z-index: 3;
      background-color: white;
    }
    tbody > tr > td {
      vertical-align: middle;
    }
  }
  display: flex;
  height: calc(100vh - 5.7rem);
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  .btn-sm {
    min-width: 60px;
    margin-bottom: 5px;
  }
  .custom-control-label::before {
    color: #fff;
    background-color: #e97d88;
    border-color: #e97d88;
  }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #28a745;
    background-color: #28a745;
  }
  .custom-switch .custom-control-label::after {
    background-color: #fff;
  }
}

.modal-full {
  width: 95%;
  max-width: none !important;
  height: 95%;
  iframe {
    min-height: 80vh;
  }
}

@media only screen and (max-width: 740px) {
  .new-navbar {
    .boxed-logo {
      height: 100px;
      padding: 15px;
    }
  }
  .image-block {
    .image-text-container {
      .image-text {
        font-size: 2.8rem;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .image-block {
    .image-text-container {
      .image-text {
        font-size: 2.2rem;
      }
    }
  }
}

@media only screen and (max-width: 580px) {
  .new-navbar {
    .boxed-logo {
      height: 75px;
      padding: 11px;
      margin-top: 30px;
    }
  }
}

// @media only screen and (max-width: 570px) {
//   .header {
//     .logos-container {
//       flex-direction: column;
//     }
//   }
// }
@media only screen and (max-width: 480px) {
  #formLogin {
    width: auto;
  }
  .new-navbar {
    .boxed-logo {
      height: 60px !important;
      padding: 8px;
      margin-top: 35px;
    }
  }
}

@media only screen and (max-width: 420px) {
  .image-block {
    .image-text-container {
      .image-text {
        font-size: 1.5rem;
      }
    }
  }
  .new-navbar {
    .boxed-logo {
      margin: 5px 10px;
      margin-left: 20px;
      margin-top: 35px;
    }
  }
}

@media only screen and (max-width: 380px) {
  .new-navbar {
    .pride-logo {
      margin-left: 1rem;
    }
    .insurecomp-logo {
      margin-right: 1rem;
    }
    .new-logo {
      height: 3rem;
    }
  }
}

@media only screen and (max-width: 360px) {
  .new-navbar {
    .boxed-logo {
      height: 50px !important;
      padding: 5px;
      margin: 5px 5px;
      margin-top: 40px;
      margin-left: 15px;
    }
  }
}

@media only screen and (max-width: 340px) {
  .new-navbar {
    .pride-logo {
      margin-left: 1rem;
    }
    .insurecomp-logo {
      margin-right: 1rem;
    }
    .new-logo {
      height: 2.5rem;
    }
  }
}

.dashboard-admin {
  .new-navbar {
    background-color: white;
    border-bottom-style: groove;
  }
  .tabs {
    .MuiTableSortLabel-root.MuiTableSortLabel-active {
      color: white;
      .MuiTableSortLabel-icon {
        color: white !important;
      }
    }
    .nav-item {
      a,
      .active {
        color: black;
      }
      .active {
        background-color: #ffd13f;
      }
    }
  }
}

.dropzone-underwriter {
  text-align: center;
  padding: 20px;
  border: 1px dashed #363333;
  background-color: #fafafa;
  color: #8a5656;
  cursor: pointer;
}

.dropZonesContainer {
  padding: 0 25px;
  .customDropZone {
    .dropzone {
      text-align: center;
      padding: 20px;
      border: 2px dashed #363333;
      background-color: #fafafa;
      color: #8a5656;
      cursor: pointer;
    }
    li {
      background: none;
      .fw-bold {
        cursor: pointer;
      }
    }
  }
}

.popup {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 9;
  top: 0;
  text-align: center;
  display: none;
  background: rgba(0, 0, 0, 0.6);
}

.popup-container {
  font-family: MainFont, Arial, Georgia, sans-serif !important;
  background-color: #23b4e8;
  border-radius: 15px;
  box-shadow: 2px 4px 12px black;
  position: absolute;
  width: 500px;
  left: 50%;
  top: 50%;
  padding: 20px;
  color: white;
  transform: translate(-50%, -50%);
  .header-text {
    font-weight: bold;
  }
}

.tab-list {
  border-bottom: 1px solid #ccc;
  padding-left: 0;
}

.tab-list-item {
  display: inline-block;
  list-style: none;
  margin-bottom: -1px;
  padding: 0.5rem 0.75rem;
  cursor: pointer;
  text-transform: uppercase;
}

.tab-list-active {
  background-color: white;
  border: solid #ccc;
  border-width: 1px 1px 0 1px;
}

@media only screen and (max-width: 1000px) {
  .header {
    .logos-container {
      .get-quote {
        button {
          // font-size: 0.7rem;
        }
      }
    }
  }
  .home3,
  .demo-container {
    .header-container {
      .header {
        .logos-container {
          .wcn-logo-container {
            height: 5.7rem;
          }
        }
      }
    }
    .strip {
      .strip-text {
        font-size: 2.1rem;
      }
    }
    .get-quote {
      button {
        // font-size: 1rem;
      }
    }
    .about-section {
      .about-summary-container {
        .about-affinity,
        .our-partners {
          font-size: 1rem;
        }
        .about-affinity-header,
        .our-partners-header {
          font-size: 2.1rem;
        }
        .our-partners {
          .our-partners-images {
            .insurecomp-logo {
              width: 8.5rem;
            }
            .ipeo-logo {
              width: 11.5rem;
            }
          }
        }
      }
      .services {
        .services-header {
          font-size: 2.1rem;
        }
        .service-item {
          font-size: 1.1rem;
          img {
            height: 5rem;
            width: 5rem;
          }
        }
      }
      .industries-we-serve {
        .industries-we-serve-header {
          font-size: 2.1rem;
        }
        .industry-item {
          font-size: 1.1rem;
          img {
            width: 5rem;
          }
        }
      }
    }
    .page-footer {
      .upper-footer {
        font-size: 1rem;
        .get-quote {
          button {
            // font-size: 0.7rem;
          }
        }
      }
      .bottom-footer {
        font-size: 0.7rem;
      }
    }
  }
}

@media only screen and (max-width: 825px) {
  .page-footer {
    .upper-footer {
      flex-wrap: wrap;
      gap: 40px;
    }
  }
}

@media only screen and (max-width: 800px) {
  .header {
    .logos-container {
      .get-quote {
        button {
          // font-size: 0.6rem;
        }
      }
    }
  }
  .home3,
  .demo-container {
    .header-container {
      .header {
        .logos-container {
          .wcn-logo-container {
            height: 5.3rem;
          }
        }
      }
    }
    .strip {
      .strip-text {
        font-size: 1.8rem;
      }
    }
    .get-quote {
      button {
        // font-size: 0.9rem;
      }
    }
    .about-section {
      .about-summary-container {
        padding: 0 2rem;
        .about-affinity,
        .our-partners {
          font-size: 0.9rem;
        }
        .about-affinity-header,
        .our-partners-header {
          font-size: 1.8rem;
        }
        .our-partners {
          .our-partners-images {
            .insurecomp-logo {
              width: 6.5rem;
            }
            .ipeo-logo {
              width: 9.5rem;
            }
          }
        }
      }
      .services {
        .services-header {
          font-size: 1.8rem;
        }
        .service-item {
          font-size: 0.9rem;
          img {
            height: 4rem;
            width: 4rem;
          }
        }
      }
      .industries-we-serve {
        .industries-we-serve-header {
          font-size: 1.8rem;
        }
        .industry-item {
          font-size: 0.9rem;
          img {
            width: 4rem;
          }
        }
      }
    }
    .page-footer {
      .upper-footer {
        flex-wrap: wrap;
        gap: 40px;
        font-size: 0.9rem;
        .get-quote {
          button {
            // font-size: 0.6rem;
          }
        }
      }
      .bottom-footer {
        font-size: 0.6rem;
      }
    }
  }
}

@media only screen and (max-width: 650px) {
  .header {
    .logos-container {
      .get-quote {
        button {
          font-size: 0.7rem;
        }
      }
    }
  }
  .home3,
  .demo-container {
    .header-container {
      .header {
        .logos-container {
          margin-left: 0.5rem;
          margin-right: 1.5rem;
          .wcn-logo-container {
            height: 4.8rem;
          }
        }
      }
    }
    .strip {
      .strip-text {
        font-size: 1.55rem;
      }
    }
    .quote-button-container {
      margin-bottom: 9rem;
    }
    .get-quote {
      button {
        // font-size: 0.8rem;
      }
    }
    .about-section-container {
      // margin-bottom: 9rem;
      .about-section {
        .about-summary-container {
          margin-bottom: 9rem;
          padding: 0 2rem;
          .about-affinity,
          .our-partners {
            font-size: 0.8rem;
          }
          .about-affinity-header,
          .our-partners-header {
            font-size: 1.55rem;
          }
          .our-partners {
            .our-partners-images {
              .insurecomp-logo {
                width: 5rem;
              }
              .ipeo-logo {
                width: 8rem;
              }
            }
          }
        }
        .video {
          margin-bottom: 9rem;
        }
        .services {
          margin-bottom: 9rem;
          .services-header {
            font-size: 1.55rem;
          }
          .service-item {
            font-size: 0.8rem;
            img {
              height: 3.5rem;
              width: 3.5rem;
            }
          }
        }
        .industries-we-serve {
          .industries-we-serve-header {
            font-size: 1.55rem;
          }
          .industry-item {
            font-size: 0.8rem;
            img {
              width: 3.5rem;
            }
          }
        }
      }
    }
    .page-footer {
      .upper-footer {
        font-size: 0.8rem;
        .get-quote {
          button {
            // font-size: 0.5rem;
          }
        }
      }
      .bottom-footer {
        font-size: 0.5rem;
      }
    }
  }
}

@media only screen and (max-width: 550px) {
  .header {
    .logos-container {
      .get-quote {
        button {
          font-size: 0.6rem;
        }
      }
    }
  }
  .home3,
  .demo-container {
    .header-container {
      .header {
        .logos-container {
          margin-left: 0.5rem;
          margin-right: 1rem;
          .wcn-logo-container {
            height: 4rem;
          }
        }
      }
    }
    .strip {
      .strip-text {
        font-size: 1.25rem;
      }
    }
    .quote-button-container {
      margin-bottom: 8rem;
    }
    .get-quote {
      button {
        // font-size: 0.65rem;
      }
    }
    .about-section-container {
      // margin-bottom: 8rem;
      .about-section {
        .about-summary-container {
          margin-bottom: 8rem;
          padding: 0 1.2rem;
          .about-affinity,
          .our-partners {
            font-size: 0.65rem;
          }
          .about-affinity-header,
          .our-partners-header {
            font-size: 1.25rem;
          }
          .our-partners {
            .our-partners-images {
              .insurecomp-logo {
                width: 4rem;
              }
              .ipeo-logo {
                width: 7rem;
              }
            }
          }
        }
        .video {
          margin-bottom: 8rem;
        }
        .services {
          margin-bottom: 8rem;
          .services-header {
            font-size: 1.25rem;
          }
          .service-item {
            font-size: 0.65rem;
            img {
              height: 3rem;
              width: 3rem;
            }
          }
        }
        .industries-we-serve {
          .industries-we-serve-header {
            font-size: 1.25rem;
          }
          .industry-item {
            font-size: 0.65rem;
            img {
              width: 3rem;
            }
          }
        }
      }
    }
    .page-footer {
      .upper-footer {
        font-size: 0.65rem;
        .get-quote {
          button {
            font-size: 0.7rem;
          }
        }
      }
      .bottom-footer {
        font-size: 0.45rem;
      }
    }
  }
}

@media only screen and (max-width: 450px) {
  .header {
    .logos-container {
      .get-quote {
        button {
          font-size: 0.5rem;
          padding: 0.4rem 0.5rem;
        }
      }
    }
  }
  .home3,
  .demo-container {
    .header-container {
      .header {
        .logos-container {
          .wcn-logo-container {
            height: 3rem;
          }
        }
      }
    }
    .strip {
      .strip-text {
        font-size: 1rem;
      }
    }
    .quote-button-container {
      margin-bottom: 7rem;
    }
    .get-quote {
      button {
        // font-size: 0.5rem;
      }
    }
    .about-section-container {
      // margin-bottom: 7rem;
      .about-section {
        .about-summary-container {
          margin-bottom: 7rem;
          padding: 0 1.2rem;
          .about-affinity,
          .our-partners {
            font-size: 0.5rem;
          }
          .about-affinity-header,
          .our-partners-header {
            font-size: 1rem;
            margin-bottom: 3rem;
          }
          .our-partners {
            .our-partners-images {
              .insurecomp-logo {
                width: 3rem;
              }
              .ipeo-logo {
                width: 6rem;
              }
            }
          }
        }
        .video {
          margin-bottom: 7rem;
        }
        .services {
          margin-bottom: 7rem;
          padding: 3rem 0;
          .services-header {
            font-size: 1rem;
            margin-bottom: 3rem;
          }
          .service-item {
            font-size: 0.5rem;
            img {
              height: 2rem;
              width: 2rem;
            }
          }
        }
        .industries-we-serve {
          .industries-we-serve-header {
            font-size: 1rem;
            margin-bottom: 3rem;
          }
          .industry-item {
            font-size: 0.5rem;
            img {
              width: 2rem;
            }
          }
        }
      }
    }
    .page-footer {
      .upper-footer {
        font-size: 0.5rem;
        .get-quote {
          button {
            // font-size: 0.35rem;
            // padding: 0.4rem 0.5rem;
          }
        }
      }
      .bottom-footer {
        font-size: 0.35rem;
      }
    }
  }
}

.claimtotalinccured {
  margin-left: -49px;
}

.class-desc-select__menu {
  width: 65vw !important;
  max-width: none !important;
}

.class-desc-select__option {
  width: auto !important;
}

.tab2,
.tab1 {
  thead {
    tr:first-child th {
      width: 10px;
    }
  }
  tbody {
    tr:first-child td {
      width: 10px;
    }
  }
}

.download-files {
  ul {
    li {
      margin-bottom: 10px;
      margin-top: 10px;
    }
  }
  .download-files-link,
  u {
    cursor: pointer;
    color: green;
    text-decoration-line: underline;
  }
  .download-files-link:visited {
    color: blue;
  }
  .download-files-link:hover {
    color: rgb(1, 65, 1);
  }
}

.MuiTableSortLabel-root {
  color: white !important;
}

.MuiTableSortLabel-root:hover {
  color: #faa541 !important;
}

.navbarcoloring {
  background-color: #ffffff;
}

.navwidth {
  width: 100% !important;
}

@media only screen and (max-width: 700px) {
  .responsiveheading {
    display: flex !important;
    // justify-content: space-around !important;
    flex-direction: column !important;
    justify-content: flex-start !important;
  }
}

@media only screen and (max-width: 700px) {
  .heading-row {
    font-weight: 400 !important;
    display: block !important;
  }
  .formTitle {
    width: 100% !important;
  }
  .table-undrques thead tbody {
    padding-bottom: 20px !important;
  }
  .logos-container {
    display: flex !important;
    justify-content: center !important;
  }
  .getquotebutton1 {
    font-size: 14px !important;
  }
  .bringcolumn {
    display: flex !important;
    flex-direction: column !important;
    margin-top: 19px !important;
    padding-top: 10px 0px !important;
  }
  .formTitle {
    margin-top: 37px !important;
    margin-bottom: 38px !important;
  }
  .responsivecolumn {
    display: contents !important;
  }
  .centerresponsive {
    display: flex !important;
    justify-content: center !important;
  }
}

@media only screen and (min-width: 1350px) {
  .applygrid {
    display: inline-grid;
    grid-template-rows: auto;
    grid-template-columns: 250px 1fr 1fr 1fr 1fr 1fr;
    grid-column-gap: 30px;
  }
}

@media only screen and (max-width: 1284px) {
  .applygrid {
    display: inline-grid;
    grid-template-rows: auto;
    grid-template-columns: 190px 110px 70px 110px 100px 110px;
    grid-column-gap: 20px;
  }
}

@media only screen and (max-width: 1170px) {
  .applygrid {
    display: inline-grid;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 30px;
  }
}

@media only screen and (max-width: 1000px) {
  .applygrid {
    display: inline-grid;
    grid-template-rows: auto;
    grid-template-columns: 2fr 1fr 1fr;
    grid-column-gap: 20px;
  }
}

@media only screen and (max-width: 700px) {
  .applygrid {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 5px;
  }
}

@media only screen and (max-width: 500px) {
  .home3 .get-quote button {
    font-size: 10px;
  }
}

// getquote page responsiveness
@media only screen and (max-width: 620px) {
  .quote-row {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: auto auto;
  }
  .imagesresponsives {
    display: inline-grid;
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 2;
    grid-column-end: 4;
    justify-content: start;
    margin-right: 1rem;
    margin-left: -1rem;
  }
  .programattributes {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 1fr;
    grid-column-start: 1;
    grid-column-end: 4;
  }
  .Sutaficafuta {
    display: grid;
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 4;
  }
  .imageinrow {
    display: flex;
    flex-direction: row !important;
  }
  .icompimagestatus {
    padding-left: 3rem;
  }
  .quote-status {
    padding-left: 3rem;
  }
}

@media only screen and (max-width: 460px) {
  .quote-status {
    padding-left: 2rem;
  }
  .icompimagestatus {
    padding-left: 2rem;
  }
}

@media only screen and (max-width: 433px) {
  .quote-status {
    padding-left: 1.5rem;
  }
  .icompimagestatus {
    padding-left: 1.5rem;
  }
  .imagesresponsives {
    margin-left: -1rem !important;
  }
}

@media only screen and (max-width: 419px) {
  .imagesresponsives {
    margin-left: -1rem !important;
  }
  .quote-status {
    padding-left: 2rem;
    font-size: 0.7rem;
  }
  .icompimagestatus {
    padding-left: 2rem;
  }
  .quote-row .peo-logo {
    width: 4rem !important;
  }
}

@media only screen and (max-width: 372px) {
  .quote-row .peo-logo {
    width: 4rem !important;
  }
  .quote-status {
    padding-left: 1rem;
    font-size: 0.6rem;
  }
  .icompimagestatus {
    padding-left: 1rem;
  }
}

@media only screen and (max-width: 348px) {
  .quote-row .peo-logo {
    width: 3rem !important;
  }
  .quote-status {
    padding-left: 1rem;
    font-size: 0.6rem;
  }
  .icompimagestatus {
    padding-left: 1rem;
  }
  .quote-table {
    margin-left: -10px !important;
  }
}

@media only screen and (max-width: 310px) {
  .quote-table {
    margin-left: -23px !important;
  }
  .quote-row .peo-logo {
    width: 2.5rem !important;
  }
  .quote-status {
    padding-left: 1rem;
    font-size: 0.5rem;
  }
  .icompimagestatus {
    padding-left: 1rem;
  }
}

@media only screen and (max-width: 276px) {
  .quote-table {
    margin-left: -33px !important;
  }
}

.label-color {
  font-size: 14px;
  font-weight: 700;
  margin-left: -83px;
}
.files {
  font-size: 14px;
  font-weight: 700;
}
.filetype {
  color: #427cbc;
  margin-left: 10%;
  font-size: 14px;
  font-weight: 600;
}
.button-padding {
  padding-inline: 110px;
}

.heading {
  display: flex;
  font-size: 22px;
  font-weight: 900;
  border-radius: 10px;
  margin-top: 50px;
  margin-left: 27%;
  margin-bottom: 50px;
  width: 50%;
  height: 20%;
  padding: 15px;
  align-self: center;
  background-color: #427cbc;
  color: white;
}
.upload-color {
  background-color: #faa63f;
  color: white;
}

.update-color {
  background-color: #427cbc;
  color: white;
}

.replace-color {
  background-color: #fdc006;
  color: white;
}

.ToggleDiv {
  width: 70%;
  margin-left: 13%;
  margin-top: 10%;
}

.Drop {
  font-family: sans-serif;
}

.dragActive {
  font-size: 2rem;
  color: #777;
}

.dropzone {
  text-align: center;
  padding: 20px;
  border: 3px dashed #eeeeee;
  background-color: #fafafa;
  color: #bdbdbd;
  margin-left: 10%;
  width: 80%;

  margin-bottom: 20px;
}

.accept {
  border-color: #107c10 !important;
}

.reject {
  border-color: #d83b01 !important;
}
.btn-color {
  background-color: #faa54e !important;
}
.dashboard-admin .nav-link:active {
  background-color: #faa54e !important;
}
.modal-dialog {
  width: 50% !important;

  height: 50% !important;
  margin-top: 13rem !important;
}
.login-container {
  display: flex;
  width: 50%;
  margin-right: 0px;
  background-color: #ffffff;
  align-items: center;
  justify-content: end;
  padding: 10px 10px 10px 8px !important;
  // color: #000000;

  .dropdown-toggle {
    font-weight: 900;
  }
  a {
    color: rgb(0, 0, 0);
    cursor: pointer;
    font-weight: 900;
    font-size: large;
    margin-right: 30px;
  }
  a:hover {
    color: #021e35;
    text-decoration: underline;
  }
}
.demo-container {
  font-size: 1rem;
  font-family: "Poppins";
  #lgin {
    margin: 0;
  }
  .grid-banner-container {
    width: 75%;
    margin-bottom: 50px;
    .grid-banner {
      .grid-banner-header {
        font-size: 3.5rem;
        margin-bottom: 27px;
      }
      .grid-banner-logo-container {
        .insurecomp-logo {
          width: 25%;
          height: auto;
          margin-right: 25px;
        }
        .affinity-logo {
          width: 30%;
          height: auto;
          margin-left: 25px;
        }
      }
    }
  }
  .table-container {
    width: 75%;
    table {
      td {
        height: 206px;
        width: 25%;
        .cell-header {
          font-size: 1.2rem;
          .header-link {
            cursor: pointer;
          }
          .header-link:hover {
            text-decoration: underline;
          }
        }
        ul {
          padding: 0;
        }
        ul > li {
          list-style: none;
          margin: 24px 0;
        }
        ul > li > span {
          cursor: pointer;
          color: #007bff;
          text-decoration: underline;
        }
      }
    }
  }
  .demo-login-container {
    .tab-content-login {
      min-height: auto;
    }
  }
  .banner-container {
    margin-bottom: 45px;
    margin-top: 90px;
    .demo-banner {
      .banner-text {
        width: 70%;
        font-weight: bold;
        font-size: 3.5rem;
      }
      .banner-video {
        margin-bottom: 100px;
      }
    }
  }
  .form-section-container {
    width: 75%;
    margin-bottom: 115px;
    .demo-input-form-section {
      /* Chrome, Safari, Edge, Opera */
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      input[type="number"] {
        -moz-appearance: textfield;
      }
      .btn {
        color: white;
        font-weight: normal;
        border-radius: 7.2px;
        color: #fff;
        background-color: #f0ad4e;
        border-color: #eea236;
        margin-top: 50px;
        box-shadow: 2px 4px 12px #eea236;
      }
      .input-form-header {
        margin-bottom: 50px;
        font-size: 2rem;
        font-weight: bold;
        color: rgb(52, 103, 174);
      }
      .form-container {
        .form-group:has(input[required]) label::after {
          content: "*";
        }
        .form-success {
          font-size: 2.5rem;
          padding: 60px 10px;
          background-color: rgb(92, 92, 92);
          color: white;
        }
      }
    }
  }
}
.sub_tab1 tbody tr td,
.sub_tab2 tbody tr td,
.sub_tab3 tbody tr td {
  text-align: center;
  font-size: 1.2rem !important;
  font-family: "Montserrat";
}

.sub_tab1 thead tr th,
.sub_tab2 thead tr th,
.sub_tab3 thead tr th {
  text-align: center;
  font-size: 1.2rem !important;
  font-family: "Montserrat";
}

@media only screen and (max-width: 1600px) {
  .sub_tab1 tbody tr td,
  .sub_tab2 tbody tr td,
  .sub_tab3 tbody tr td {
    text-align: center;
    font-size: 1.2rem !important;
    font-family: "Montserrat";
  }

  .sub_tab1 thead tr th,
  .sub_tab2 thead tr th,
  .sub_tab3 thead tr th {
    text-align: center;
    font-size: 1.2rem !important;
    font-family: "Montserrat";
  }
}

@media only screen and (max-width: 1500px) {
  .sub_tab1 tbody tr td,
  .sub_tab2 tbody tr td,
  .sub_tab3 tbody tr td {
    text-align: center;
    font-size: 1rem !important;
    font-family: "Montserrat";
  }

  .sub_tab1 thead tr th,
  .sub_tab2 thead tr th,
  .sub_tab3 thead tr th {
    text-align: center;
    font-size: 1rem !important;
    font-family: "Montserrat";
  }
}
@media only screen and (max-width: 1400px) {
  .sub_tab1 tbody tr td,
  .sub_tab2 tbody tr td,
  .sub_tab3 tbody tr td {
    text-align: center;
    font-size: 0.9rem !important;
    font-family: "Montserrat";
  }

  .sub_tab1 thead tr th,
  .sub_tab2 thead tr th,
  .sub_tab3 thead tr th {
    text-align: center;
    font-size: 0.9rem !important;
    font-family: "Montserrat";
  }
}

@media only screen and (max-width: 1300px) {
  .sub_tab1 tbody tr td,
  .sub_tab2 tbody tr td,
  .sub_tab3 tbody tr td {
    text-align: center;
    font-size: 0.8rem !important;
    font-family: "Montserrat";
  }

  .sub_tab1 thead tr th,
  .sub_tab2 thead tr th,
  .sub_tab3 thead tr th {
    text-align: center;
    font-size: 0.8rem !important;
    font-family: "Montserrat";
  }
}

// .Admin__Dashboard {
//   padding-left: 120px !important;
//   padding-right: 120px !important;
// }

.Admin__Dashboard {
  .MuiPaper-root {
    box-shadow: 1px 1px 12px -1px;
  }
}
.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.pink-sign {
  font-weight: 800;
  margin-left: 15px;
  color: rgb(255, 255, 255);
  background-color: #c0224d;
  border-radius: 50%;
  width: 29px;
  height: 29px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  margin-top: 20px;
}
.pink-header {
  color: #c0224d;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: bold;
  display: flex;
}
#preuw_companyName,
#preuw_website,
#preuw_fein,
#preuw_street1,
#preuw_zipCode1,
#preuw_cityName1,
#preuw_state1,
#preuw_street2,
#preuw_cityName2,
#preuw_zipCode2,
#preuw_state2,
#preuw_EmailContactName,
#preuw_emailEmailId,
#preuw_jobDescription,
#preuw_PhoneNumber,
#preuw_entityType,
#preuw_yearsInBusiness,
#preuw_expectedStartDate,
#preuw_descriptionOfOperations,
#preuw_zipCode,
#preuw_cityName,
#preuw_street,
#preuw_state,
#preuw_workerComp,
#preuw_FullTime,
#preuw_partTime,
#preuw_AnnualPayroll,
#preuw_PhoneNumber,
#preuw_domain,
#preuw_Source,
#preuw_destination {
  width: 90%;
}
#preuw_entityType {
  font-size: 13px !important;
}

// .css-g1d714-ValueContainer {
//   height: 20px !important;
// }
.form-group {
  select,
  input {
    height: 35px;
    color: #2b2d31;
    // font-size: 14px;
    padding-left: 5px;
  }
  label {
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    // font-size: 11px;
    color: #333;
    margin-bottom: 0;
    &.mandatory::after {
      content: "*";
      padding-left: 2px;
      color: red !important;
    }
  }
  p.errMsg {
    color: red;
    font-size: 11px;
  }
}
.notes-row {
  padding-inline: 15px;
  margin-top: -15px;

  .form-group {
    width: 100%;
  }
}

#preuw_descriptionOfOperations {
  height: 35px !important;
}
.ChevronRight {
  position: absolute;
  right: 8%;

  bottom: 40%;
  z-index: 10;
  border: none;
  opacity: 0.5;
  border-radius: 50%;
  color: #312f2f;
  font-weight: bold;
  font-size: 42px;
  transition: 0.3s;
}
.ChevronLeft {
  position: absolute;
  left: 8%;

  bottom: 40%;
  z-index: 10;
  border: none;
  opacity: 0.5;
  border-radius: 50%;
  color: #312f2f;
  font-weight: bold;
  font-size: 42px;
  transition: 0.3s;
}

.ChevronLeft:hover {
  color: black !important;
  font-size: 51px;
  opacity: 0.8;
}
.ChevronRight:hover {
  color: rgb(37, 21, 21) !important;
  font-size: 51px;
  opacity: 0.8;
}

@media only screen and (max-width: 1600px) {
  .ChevronRight {
    // top: 78%;

    font-size: 38px;
    transition: 0.3s;
  }
  .ChevronLeft {
    // top: 78%;

    font-size: 38px;
    transition: 0.3s;
  }

  .ChevronLeft:hover {
    color: black !important;
    font-size: 48px;
  }
  .ChevronRight:hover {
    color: rgb(37, 21, 21) !important;
    font-size: 48px;
  }
}
@media only screen and (max-width: 1400px) {
  .ChevronRight {
    font-size: 36px;
  }
  .ChevronLeft {
    font-size: 36px;
  }

  .ChevronLeft:hover {
    color: black !important;
    font-size: 43px;
  }
  .ChevronRight:hover {
    color: rgb(37, 21, 21) !important;
    font-size: 43px;
  }
}
@media only screen and (max-width: 750px) {
  .ChevronRight {
    font-size: 30px;
  }
  .ChevronLeft {
    font-size: 30px;
  }

  .ChevronLeft:hover {
    color: black !important;
    font-size: 34px;
  }
  .ChevronRight:hover {
    color: rgb(37, 21, 21) !important;
    font-size: 34px;
  }
}
.form-group {
  // margin-bottom: 38px;
  input[type="radio"] {
    width: 20px;
    height: 20px;
    display: grid;
    place-content: center;
    background-color: transparent;
    cursor: pointer;
  }
  input[type="radio"]:after {
    content: "";
    width: 0.65em;
    height: 0.65em;
    border-radius: 40%;
    transform: scale(0);
    transition: 2s transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);
  }
  input[type="radio"]:checked:after {
    transform: scale(1);
    border: 1px solid #0763ee83;
    box-shadow: #0763ee83;
    transition: 2s transform ease-in-out;
  }
  .form-group {
    margin-bottom: 2px;
    // select,
    label,
    input {
      font-size: 16px !important;
      color: black;
    }
    select,
    input {
      box-shadow: 0.5px 1px 4px grey;
    }
  }
  select,
  textarea,
  input[type="text"],
  input[type="email"] {
    width: 100%;
    border-radius: 4px;
    border: 1px solid #fff;

    box-shadow: 0.5px 1px 4px grey;
  }
  input[type="checkbox"] {
    box-shadow: 0.5px 1px 4px transparent !important;
  }
}
#preuw_workerComp {
  input {
    box-shadow: none !important;
  }
  .css-g1d714-ValueContainer {
    padding: 0px !important;
  }
  .css-b8ldur-Input {
    margin: 0px !important;
  }
}

#preuwmain_container {
  border: 6px solid orange;
  border-radius: 20px;
}
